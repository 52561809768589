/* eslint-disable */
import * as React from "react"
import { FiltersWrapper } from "../containers/MenuContainer";

export const Filters = ({ items, handleSetActive, active }) => {

  return (
    <FiltersWrapper>
      <ul>
        {
          items.map((item, index) => {
            return (
              <li 
                key={`filter-${index}`} 
                onClick={() => handleSetActive(item.uid)} 
                className={`${active.indexOf(item.uid) !== -1 && "active"}`}
              >
                <span>{item.data.filter.text}</span>
              </li>
            )
          })
        }
      </ul>
    </FiltersWrapper>
  )
};
