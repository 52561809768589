import * as React from "react"
import { connect } from 'react-redux'
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import PhotosGallery from "react-photo-gallery"

import Layout from "../components/Layout"
import Header from "../components/Header"
import ModalPhoto from '../components/Modal/ModalPhoto'
import Photo from "../components/Photo"

import { handleModalBanner } from '../actions/modal'
import { computePhotosCollection } from "../../helpers/photos"
import { MainContainer } from "../containers/MainContainer"
import { Filters } from "../components/Filters"

const Gallery = (props) => {
  
  const { modalBanner, handleModalBanner, pageContext: { filters }} = props;
  const [active, setActive] = React.useState([]);
  const handleSetActive = (uid) => {
    setActive(active.indexOf(uid) !== -1 ? active.filter(i => i !== uid) : [...active, uid]);
  };
  
  const photos = computePhotosCollection(props.data.photos.nodes, active);
  const ModalPhotos = computePhotosCollection(props.data.ModalPhotos.nodes, active);
  
  return (
    <Layout>
      <Helmet title={`Marlo Disch - Galerie`}>
        <link 
          rel="stylesheet" 
          type="text/css" 
          href="https://unpkg.com/xzoom/dist/xzoom.css" 
          media="all"
        />
      </Helmet>
      <Header slug={'galerie'} />
      <Filters items={filters} handleSetActive={handleSetActive} active={active} />
      <MainContainer>
        {photos.length > 0 && (
          <React.Fragment>
            <PhotosGallery
              photos={photos}
              direction={"column"}
              renderImage={(data) => {
                const { photo, index, top, left } = data;
                return <Photo
                  key={`item-${index}`}
                  item={photo}
                  handleModalBanner={handleModalBanner}
                  modalBanner={modalBanner}
                  index={index}
                  position={{
                    top: top,
                    left: left,
                    width: photo.width,
                    height: photo.height
                  }}
                />
              }}
            />
            <ModalPhoto images={ModalPhotos} />
          </React.Fragment>
        )}
      </MainContainer>
    </Layout>
  )
}

const mapStateToProps = (state) => ({...state.modalBanner});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
export const pageQuery = graphql`
  query GalleryPage {
    photos: allPrismicPhoto(sort: {order: DESC, fields: first_publication_date}) {
      nodes {
        uid
        data {
          description {
            html
            text
          }
          featured
          formats
          legend {
            html
            text
          }
          photo {
            alt
            copyright
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 400, webpQuality: 5) {
                  srcWebp
                  presentationHeight
                  presentationWidth
                  srcSetWebp
                  sizes
                }
                gatsbyImageData(
                  webpOptions: {
                    quality: 5
                  }, 
                  formats: WEBP, 
                  placeholder: DOMINANT_COLOR, 
                )
              }
            }
          }
          body {
            ... on PrismicPhotoBodyFormat {
              id
              items {
                format {
                  slug
                  document {
                    ... on PrismicFormat {
                      id
                      data {
                        format {
                          html
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicPhotoBodyCategoriesFiltres {
              id
              items {
                filter {
                  slug
                  document {
                    ... on PrismicFilter {
                      id
                      data {
                        filter {
                          text
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ModalPhotos: allPrismicPhoto(sort: {order: DESC, fields: first_publication_date}) {
      nodes {
        uid
        data {
          description {
            html
            text
          }
          featured
          formats
          legend {
            html
            text
          }
          photo {
            alt
            copyright
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 1920, webpQuality: 40) {
                  srcWebp
                  presentationHeight
                  presentationWidth
                  sizes
                }
              }
            }
          }
          body {
            ... on PrismicPhotoBodyFormat {
              id
              items {
                format {
                  slug
                  document {
                    ... on PrismicFormat {
                      id
                      data {
                        format {
                          html
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicPhotoBodyCategoriesFiltres {
              id
              items {
                filter {
                  slug
                  document {
                    ... on PrismicFilter {
                      id
                      data {
                        filter {
                          text
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`